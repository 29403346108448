<template>
  <div
    class="flex items-center h-[89px] mt-3 overflow-hidden cursor-pointer"
    @click="emit('update:selectedQuarterId',itemId)"
  >
    <img
      :src="image"
      :alt="t('`flat.decorationType.label')"
      class="w-1/2 h-full object-cover"
    >
    <div
      class="flex flex-col  justify-center w-1/2 h-full pl-3 border-2 border-l-0 border-primary-light"
      :class="{'bg-primary-light': itemId === selectedQuarterId, 'rounded-t-md': isFirst, 'rounded-b-md': isLast}"
    >
      <div
        class="font-semibold"
        :class="{'text-primary': itemId === selectedQuarterId}"
      >
        {{ t('building.constructionProgress.quarter.label', {value: QUARTER[quarterName]} ) }}
      </div>
      <div class="text-neutral-500 text-[12px]">
        {{ t('building.constructionProgress.photos.label.full', imagesCount) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import defaultImage from 'assets/images/building/decoration-default-image.svg'
import { QUARTER } from '~/common/constants/date/Quarter'
import { BuildingQuarters } from '~/modules/building/types/Building.quarters'

defineProps({
  itemId: {
    type: Number,
    required: true,
  },
  quarterName: {
    type: String as PropType<keyof BuildingQuarters>,
    required: true,
  },
  image: {
    type: String,
    required: true,
    default: defaultImage,
  },
  imagesCount: {
    type: Number,
    required: true,
  },
  selectedQuarterId: {
    type: Number,
    required: true,
  },
  isFirst: {
    type: Boolean,
    required: true,
  },
  isLast: {
    type: Boolean,
    required: true,
  },
})
const { t } = useI18n()
const emit = defineEmits(['update:selectedQuarterId'])

</script>
