<template>
  <div>
    <h2 v-if="isHeaderVisible" class="font-semibold  text-neutral-900 text-2xl">
      {{ $t('building.page.constructionProgress.label.full') }}
    </h2>
    <div class="flex flex-col md:flex-row lg:flex-row xl:flex-row gap-x-3 mt-[25px]">
      <div class="w-full md:w-2/3 lg:w-2/3 xl:w-2/3">
        <building-page-quarters-select-quarter
          v-model:selected-year="year"
          :years="building.constructionProgress"
          class="flex md:hidden xl:hidden mb-6"
        />
        <custom-carousel
          :images="quartersImages"
          max-height="450px"
          :min-height="isSm ? '150px' : '450px'"
          :default-image="defaultImage"
        />
      </div>
      <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3  pl-5">

        <building-page-quarters-select-quarter
          v-model:selected-year="year"
          :years="building.constructionProgress"
          class="hidden md:flex xl:flex mb-7"
        />
        <building-page-quarter-item
          v-for="(item, index) in sortedQuarters"
          :key="index"
          :quarter-name="item[0] as keyof BuildingQuarters"
          :image="item[1].images[0].link"
          :images-count="item[1].images.length"
          :item-id="item[1].id"
          :selected-quarter-id="selectedQuarterId"
          :is-first="index === 0"
          :is-last="index === Object.entries(building.constructionProgress[year]).length - 1"
          @update:selected-quarter-id="selectedQuarterId = $event"
          @click="selectedQuarter = building.constructionProgress[year][item[0]]"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import defaultImage from 'assets/images/building/decoration-default-image.svg'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import BuildingPageQuartersSelectQuarter
  from '~/modules/building/components/page/constructionProgress/BuildingPageQuartersSelectQuarter.vue'
import { Image } from '~/common/types/image/Image'
import BuildingPageQuarterItem
  from '~/modules/building/components/page/constructionProgress/BuildingPageQuarterItem.vue'
import { BuildingQuarters } from '~/modules/building/types/Building.quarters'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  building: {
    type: Object as PropType<BuildingExpanded>,
    required: true,
  },
  isHeaderVisible: {
    type: Boolean,
    default: true,
  },
})

const isSm = computed(() => !useAppStateStore().breakpoints.isMd)

const maxYear = computed<number>(() => Math.max(...Object.keys(props.building?.constructionProgress).map(year => Number(year))))

const year = ref<number>(maxYear.value)

const sortedQuarters = computed(() => {
  const order = { first: 4, second: 3, third: 2, fourth: 1 }
  const constructionProgress = props.building?.constructionProgress[year.value]

  return Object.entries(constructionProgress)
    .sort((a, b) => (order[b[0]] || 0) - (order[a[0]] || 0)).reverse()
})

const selectedQuarter = ref(props.building?.constructionProgress[year.value].fourth ||
    props.building?.constructionProgress[year.value].third ||
    props.building?.constructionProgress[year.value].second ||
    props.building?.constructionProgress[year.value].first)

const selectedQuarterId = ref<number>(selectedQuarter.value.id)

const quartersImages = ref<Array<Image>>(selectedQuarter.value ? selectedQuarter.value.images : [])

watchEffect(() => {
  quartersImages.value = selectedQuarter.value ? selectedQuarter.value.images : []
})
watch(year, newYear => {
  selectedQuarter.value =
      props.building?.constructionProgress[newYear]?.fourth ||
      props.building?.constructionProgress[newYear]?.third ||
      props.building?.constructionProgress[newYear]?.second ||
      props.building?.constructionProgress[newYear]?.first
  selectedQuarterId.value = selectedQuarter.value.id
})

</script>
