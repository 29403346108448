<template>
  <custom-dropdown
    button-class="w-full items-center px-4 py-3"
    body-class="!p-0"
    color="secondary"
    size="none"
  >
    <template #triggerContent>
      <div class="font-semibold">
        {{ selectedYear }}
      </div>
    </template>
    <custom-list-group class="w-full">
      <custom-list-group-item
        v-for="(year, index) in numYears.sort((a, b) => b - a)"
        :key="index"
        @click="selectYear(Number(year))"
      >
        {{ year }}
      </custom-list-group-item>
    </custom-list-group>
  </custom-dropdown>
</template>
<script setup lang="ts">
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomListGroup from '~/ui/list-group/CustomListGroup.vue'
import CustomListGroupItem from '~/ui/list-group/CustomListGroupItem.vue'
import { BuildingQuarters } from '~/modules/building/types/Building.quarters'

const props = defineProps({
  years: {
    type: Object as PropType<{ [key: number]: BuildingQuarters }>,
    required: true,
  },
  selectedYear: {
    type: Number,
    required: true,
  },
})

const numYears = computed(() => Object.keys(props.years).map(year => Number(year)))

const emits = defineEmits<{(e: 'update:selectedYear', value: number): void
}>()

const selectYear = (year: number) => {
  emits('update:selectedYear', year)
}

</script>
