<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.constructionProgress.label') }}
    </template>
    <custom-button-icon
      icon="building/building-process"
      icon-class="text-[1.57rem]"
      color="secondary"
      round
      @click="showAuthModalOrCallback(getConstructionProgress)"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    size="lg"
    :drawer-props="{fullHeight: true}"
    :modal-props="{size: 'w-full max-w-[1000px]', contentClass: 'min-h-[570px]', bodyClass: isFetching ? 'flex justify-center items-center' : '', centered: false, hideFooter: true}"
  >
    <template #header>
      <h2 class="font-semibold  text-neutral-900 text-2xl">
        {{ $t('building.page.constructionProgress.label.full') }}
      </h2>
    </template>
    <div v-if="isFetching" class="flex justify-center items-center">
      <custom-cube-spinner />
    </div>
    <building-page-construction-progress
      v-else-if="!isConstructionProgressEmpty && expandedBuilding"
      :is-header-visible="false"
      :building="expandedBuilding"
    />
    <div v-else>
      {{ $t('building.actions.constructionProgress.emptyConstructionProgress') }}
    </div>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { Building } from '~/modules/building/types/Building'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import BuildingPageConstructionProgress
  from '~/modules/building/components/page/constructionProgress/BuildingPageConstructionProgress.vue'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { BuildingService } from '~/modules/building/services/BuildingService'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { isEmpty } from '~/common/helpers/checkers'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const service = new BuildingService()

const expandedBuilding = ref<BuildingExpanded | null>(null)

const isFetching = ref(false)

const isModalOpen = ref(false)

const isConstructionProgressEmpty = computed(() => isEmpty(expandedBuilding.value?.constructionProgress))

const getConstructionProgress = () => {
  isModalOpen.value = true
  if (expandedBuilding.value) {
    return
  }
  isFetching.value = true
  service.getBuilding(props.building.id)
    .then(buildingData => {
      expandedBuilding.value = buildingData
    })
    .finally(() => {
      isFetching.value = false
    })
}
</script>
