<template>
  <custom-modal
    v-model="isModalOpen"
    :hide-header="true"
    :hide-footer="true"
    size="fullscreen"
  >
    <template #body>
      <div class="grow max-h-full scrollbar-thin overflow-y-auto">
        <building-list-map
          :buildings="buildings"
          :without-flats-count="withoutFlatsCount"
          with-close-button
          @close-map-modal="isModalOpen = false"
        />
      </div>
    </template>
  </custom-modal>
</template>

<script setup lang="ts">
import type { Building } from '~/modules/building/BuildingModule'
import type { SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import CustomModal from '~/ui/modals/CustomModal.vue'
import BuildingListMap from '~/modules/building/components/map/BuildingListMap.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  buildings: {
    type: Array as PropType<Array<Building | SuggestionBuilding>>,
    required: true,
  },
  withoutFlatsCount: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

</script>
